<template>
    <div class="container">
     
      
        <p v-if="!connectedAccountId && !accountCreatePending">Vous n'avez pas encore renseigné vos informations bancaires</p>

        <div v-else>
         
          <table v-if="!showEdition"  class="table">
            <thead>
              <tr>
                <th>
                  Banque
                </th>
                <th>
                  IBAN
                </th>
              
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="item in bankAccounts"
                :key="item.id"
              >
                <td>
                  {{ item.bank_name }}
                </td>
                <td>
                ******************* {{ item.last4 }}
                </td>
              
              </tr>
            </tbody>
          </table>
          <br />
          <b-button v-if="!showEdition" @click="editInfos">Modifier mes informations</b-button>
          
          <div id="embedded-management-container" />

         
        </div>
       
        <div v-if="!accountCreatePending && !connectedAccountId">
          <b-button @click="createAccount">Renseigner mes informations</b-button>
        </div>
        <div id="embedded-onboarding-container" />
        <p v-if="error" class="error">Something went wrong!</p>
        <div v-if="connectedAccountId || accountCreatePending" class="dev-callout">
          <!-- <p v-if="connectedAccountId">Your connected account ID is: <code class="bold">{{ connectedAccountId }}</code></p> -->
          <p v-if="accountCreatePending">Chargement...</p>
          <!-- <p v-if="onboardingExited">The Account Onboarding component has exited</p> -->
        </div>
        
        <br /><br />
    </div>
  </template>
  
  <script>
  import { loadConnectAndInitialize } from '@stripe/connect-js'
import { APIRequest,Auth } from '@simb2s/senseye-sdk';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { BButton,BFormInput,BOverlay, BRow, BCol,BCardText,BFormGroup,BFormFile, BMedia,BCard, BFormCheckbox, BFormTextarea} from 'bootstrap-vue'
  export default {
    components: {
      BButton,
      ToastificationContent
    
    },
    data() {
      return {
        accountCreatePending: false,
        loading:false,
        onboardingExited: false,
        showEdition:false,
        error: false,
        linkUrl:'',
        bankAccounts:[],
        connectedAccountId: null,
        stripeConnectInstance: null,
      };
    },
    methods: {
      async editProfile()
      {
        this.loading=true
        let datas = {}
          datas.stripe_id = this.connectedAccountId
         
          //datas.stl_files = this.form.stl_files
          
          try{
               
              await Auth.update_account(localStorage.id_user_senseye_auth, datas)
              this.loading=false
              this.$toast({
                  component: ToastificationContent,
                  props: {
                      title: 'Votre profil a bien été modifié !',
                      icon: 'CheckIcon',
                      variant: 'success',
                  },
              })

              
            
              
          } catch (error) {
            
            this.loading=false
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Une erreur est survenue.',
                    icon: 'AlertCircleIcon',
                    variant: 'warning',
                },
            })
            
          }


        
      },
      async editInfos() {
        this.showEdition=true
        const fetchClientSecret = async () => {
              let Parameters={
                account: this.connectedAccountId
              }
              let response = await APIRequest.account_session_stripe(Parameters)
  
              if (!response) {
                // Handle errors on the client side here
                const { error } = await response.json();
                throw ("An error occurred: ", error);
              } else {
                const { client_secret: clientSecret } = response;
                return clientSecret;
              }
            };

        this.stripeConnectInstance = loadConnectAndInitialize({
              publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY_TEST,
              fetchClientSecret,
              appearance: {
                overlays: "dialog",
                variables: {
                  colorPrimary: "#000000",
                },
              },
            });

        const container2 = document.getElementById("embedded-management-container");
        
        const accountManagement = this.stripeConnectInstance.create('account-management');
        container2.appendChild(accountManagement);
      },

      async getStripeInfos(){
        let user_infos = await APIRequest.get_stripe_infos(this.connectedAccountId)
        console.log('dataas')
        console.log(user_infos)

        
        
    
        this.bankAccounts=user_infos.data
      },
      async createAccount() {
        this.showEdition=true
        this.accountCreatePending = true;
        this.error = false;

        let json = await APIRequest.create_stripe_account(localStorage.id_user_senseye_auth)
      
        
            this.accountCreatePending = false;
            this.connectedAccountId = json.account;
  
            const fetchClientSecret = async () => {
              let Parameters={
                account: this.connectedAccountId
              }
              let response = await APIRequest.account_session_stripe(Parameters)
  
              if (!response) {
                // Handle errors on the client side here
                const { error } = await response.json();
                throw ("An error occurred: ", error);
              } else {
                const { client_secret: clientSecret } = response;
                return clientSecret;
              }
            };
  
            
            this.stripeConnectInstance = loadConnectAndInitialize({
              publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY_TEST,
              fetchClientSecret,
              appearance: {
                overlays: "dialog",
                variables: {
                  colorPrimary: "#000000",
                },
              },
            });

           
  
            const container = document.getElementById("embedded-onboarding-container");
            const embeddedOnboardingComponent = this.stripeConnectInstance.create("account-onboarding");
            embeddedOnboardingComponent.setOnExit(() => {
              console.log('User exited the onboarding flow');
              this.onboardingExited=true
              this.editProfile()
              // ICI on ajoute l'id stripe au compte user
            });
            container.appendChild(embeddedOnboardingComponent);
          
        
      },
    },
    async mounted(){
      let user_data=await Auth.get_user_with_id(localStorage.id_user_senseye_auth)
   
      this.connectedAccountId=user_data.stripe_id
      if(this.connectedAccountId)
      {
        this.getStripeInfos()
        
      }
    }
  };
  </script>
  
  